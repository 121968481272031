import { Injectable } from '@angular/core';
import { Activity } from 'src/app/classes/Activity/Actvity';

@Injectable({
  providedIn: 'root'
})
export class ActivityLogService {

  constructor() { }
  public activities: Activity[] = [];

  public appendActivity(newActivity: Activity): void {
    const doesExist = this.activities.some(activity =>
      activity.message === newActivity.message &&
      activity.type === newActivity.type);
    if (!doesExist) {
      this.activities.push(newActivity);
    }
  }

  public removeActivity(activity: Activity) {
    const temp = this.activities.filter(item => item.id !== activity.id)
    this.activities = temp;
  }

  currentActivity: Activity | null = null;
  updateActivity(id: string, state: number, max: number) {
    if(this.currentActivity === null) {
      this.currentActivity = this.activities.find(activity => activity.id === id) ?? null;
      if(this.currentActivity) {
        this.currentActivity.max = max;
        this.currentActivity.progress = state;
      }
    } else {
      this.currentActivity.progress = state;
    }
  }

}



// public appendActivity(activity: Activity) {
//   let existingActivity: Activity[] = [];

//   if (this.activities.length > 0 && activity.sourceId) {
//     for (let i = 0; i < this.activities.length; i++) {
//       if (!this.activities[i].sourceId) continue;
//       if (this.activities[i].sourceId !== activity.sourceId) continue;
//       existingActivity = this.activities.filter(act => act.sourceId === activity.sourceId);
//       break;
//     }
//   }

//   if (existingActivity.length < 1) {
//     this.activities.push(activity);
//   }
// }

