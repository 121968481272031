import { RandomId } from "src/app/util/RandomId";

export type ActivityType = 'success' | 'failure' | 'warning' | 'info' | 'download';
export type Message = string;
export type Timestamp = Date;
export type Id = string;
export type SourceId = string;


export type Activity = {
  id: Id,
  message: Message,
  type: ActivityType,
  timestamp: Timestamp
  userDismiss?: boolean
  sourceId?: SourceId
  progress?: number
  max?: number
};

export type INewActivity = Omit<Activity, 'id'>;

/**
* Generates a new activity object with a unique ID.
* 
* This function takes an object containing a message, activity type, and timestamp,
* then returns a new object that includes a unique ID along with the provided fields.
* 
* @param {INewActivity} params - The parameters for creating a new activity.
* @param {Message} params.message - The message associated with the activity.
* @param {ActivityType} params.type - The type of the activity, which can be 'success', 'failure', or 'warning'.
* @param {Timestamp} params.timestamp - The timestamp when the activity occurred.
* @param {Progress} params.progress - The timestamp when the activity occurred.
* @returns {Activity} The newly created activity object with a unique ID.
* 
* @example
* ```typescript
* const newActivityExample = newActivity({
*   message: 'User login attempt',
*   type: 'success',
*   timestamp: new Date()
* });
* // Output might be something like:
* // { id: 'r4nd0m1d', message: 'User login attempt', type: 'success', timestamp: Date }
* ```
*/
export default function newActivity({ message, type, timestamp, userDismiss, progress, max}: INewActivity): Activity {
  return { id: RandomId(), message, type, timestamp, userDismiss, progress: progress, max };
}

